// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  stage: 'test',
  production: false,
  groupIdUrl: "https://login.test.doka.com",
  yardCamApiUrl: "https://azwe-func-test-yardc.azurewebsites.net",
  groupApiUrl: "https://api.test.doka.com",
  yardApiUrl: "https://yard-api.dev.doka.com",
  translationsApiBaseUrl: "https://yard-api.dev.doka.com",
  identityServerClientId: "3ce1b12d-000a-46d4-9b92-762548fdc99b",
  powerbi: "https://emiotapi.test.doka.com/reports/embed-token",
};

export const authCodeFlowConfig = {
  issuer: environment.groupIdUrl,
  strictDiscoveryDocumentValidation: false,
  redirectUri: "https://emiot.test.doka.com/login",
  clientId: environment.identityServerClientId,
  scope:
    "openid profile roles emiot-api.doka.com/emiot.report yard-api.doka.com/users.read yard-api.doka.com/branches.read",
  responseType: "code",
  showDebugInformation: true,
  postLogoutRedirectUri: "https://emiot.test.doka.com/logout",
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
